<template>
	<div
		ref="popupCustomer"
		class="modal fade"
		id="popupCustomer"
		data-bs-backdrop="static"
		tabindex="-1"
		role="dialog"
		aria-labelledby="popupCustomer"
		aria-hidden="true"
	>
		<div
			class="
      modal-dialog 
      modal-dialog-centered"
			role="document"
		>

			<div class="modal-content">

				<div class="modal-header">
					<span class="
            font-nunito 
            font-18 
            font-700">
						Tambahkan Informasi Pelanggan
					</span>
					<button
						type="button"
						ref="closePopupCustomer"
						@click="resetSearch()"
						class="btn-close"
						data-bs-dismiss="modal"
						aria-label="Close"
					></button>

				</div>

				<div class="modal-body">
					<div class="d-flex flex-column">
						<div class="search-element">
							<el-input
								ref="searchBar"
								v-model="keyword"
								@keyup.native="customerSearch()"
								placeholder="Cari Nama, No. Telpon, atau Email Pelanggan"
								class="search-grey text-grey"
							>
								<img
									slot="prefix"
									class="icon-search"
									src="@/assets/icons/icon-search.svg"
									alt="Icon Search"
								>
							</el-input>
						</div>

						<template v-if="onSearch">
							<template v-if="loading">
								<customer-list-skeleton />
							</template>
							<template v-else>
								<customer-list v-if="customerList.length" />
								<template v-else>
									<div class="m-auto py-3">
										<img
											src="@/assets/illustration/customer-illustration.svg"
											class="w-100"
											alt="Illustration Customer"
										>
										<div class="font-roboto font-16 font-400 mt-3">
											Pelanggan yang dicari tidak ditemukan
										</div>
									</div>
								</template>
							</template>
						</template>

						<template v-else>
							<div class="m-auto py-3">
								<img
									src="@/assets/illustration/customer-illustration.svg"
									class="w-100"
									alt="Illustration Customer"
								>
								<div class="font-roboto font-16 font-400 mt-3">
									Cari untuk melihat data informasi pelanggan
								</div>
							</div>

						</template>
					</div>
				</div>

				<div class="modal-footer">
					<button
						type="button"
						class="btn btn-primary py-2 w-100"
						data-bs-target="#popupCustomerForm"
						data-bs-toggle="modal"
						data-bs-dismiss="modal"
					>
						<span class="font-nunito font-16 font-700 text-white">
							Tambahkan Pelanggan Baru
						</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

import CustomerList from "@/components/customer/CustomerList";
import CustomerListSkeleton from "@/components/skeleton/CustomerListSkeleton";

export default {
	components: {
		CustomerList,
		CustomerListSkeleton
	},
	data() {
		return {
			keyword: "",
			onSearch: false,
			loading: false
		};
	},
	computed: {
		...mapState("user", ["customerList", "customerSelected"])
	},
	methods: {
		...mapActions("user", ["getCustomers"]),
		...mapMutations("user", ["RESET_LIST_CUSTOMER"]),

		resetSearch() {
			this.keyword = "";
			this.onSearch = false;
			this.RESET_LIST_CUSTOMER();
		},

		async searchCustomer() {
			let value = this.keyword;
			let input = value.charAt(0);
			let searchKeyword = "";
			if (input === "0") {
				searchKeyword = this.keyword.substring(1);
			} else {
				searchKeyword = value;
			}
			try {
				const resp = await this.getCustomers({
					params: {
						search: searchKeyword
					}
				});
				if (resp.status === 200) {
					this.loading = false;
				}
			} catch (error) {
				this.$message({
					showClose: true,
					message: error.message,
					type: "error"
				});
			}
		},
		customerSearch() {
			this.onSearch = true;
			this.loading = true;
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				this.keyword.length !== 0
					? this.searchCustomer()
					: (this.onSearch = false);
				this.loading = false;
			}, 1000);
		}
	},
	watch: {
		customerSelected: function() {
			if (Object.keys(this.customerSelected).length) {
				this.$refs.closePopupCustomer.click();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.modal-body {
	overflow-y: auto;
	overflow-x: hidden;
}

.modal-footer {
	padding-top: 0;
}
</style>