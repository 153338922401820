<template>
  <div class="col-3 mb-4 px-3">
    <div class="card-product d-flex flex-column">
      <div class="image mx-auto mb-3">
        <img 
          :src="defaultImageProduct"
          class="img-fluid"
          @error="defaultImageProduct"
          alt="">
      </div>
      <div class="description text-center my-auto">
        <skeleton-loader-vue
          type="rect"
          :width="100"
          :height="15"
          :radius="4"
          class="mb-2 mx-auto"
          animation="fade"
        />
        <skeleton-loader-vue
          type="rect"
          :width="100"
          :height="10"
          :radius="4"
          class="mb-2 mx-auto"
          animation="fade"
        />
        <skeleton-loader-vue
          type="rect"
          :width="100"
          :height="10"
          :radius="4"
          class="mb-2 mx-auto"
          animation="fade"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IhsMixin from '@/mixins/ihs.mixins.js'

export default {
  mixins: [IhsMixin],
}
</script>