export default{
  data(){
    var checkName = (rule, value, callback) => {
      if (!value) {
        this.nameError = true
        return callback(new Error('Mohon masukan nama'));
      }
      setTimeout(() => {
        if (value.length < 2) {
          this.nameError = true
          callback(new Error('Nama minimal 2 huruf'));
        } else {
          this.nameError = false
          callback();
        }
      }, 1000);
    };
    var checkPhoneNumber = (rule, value, callback) => {
      if (!value) {
        this.phoneNumberError = true
        return callback(new Error('Mohon masukan nomer telepon'));
      } else {
        this.phoneNumberError = false
        callback();
      }
  
    };
    var checkEmail = (rule, value, callback) => {

      setTimeout(() => {
        let format = /^$|^[^@\s]+@[^@\s]+$/
        if (!format.test(value)) {
          this.emailError = true
          callback(new Error('Format email tidak valid'));
        } else {
          this.emailError = false
          callback();
        }
      }, 1000);
    };
    var checkGender = (rule, value, callback) => {
      if (!value) {
        this.genderError = true
        callback(new Error('Mohon pilih jenis kelamin'));
      }
      setTimeout(() => {
        if (!value) {
          this.genderError = true
          callback(new Error('Mohon pilih jenis kelamin'));
        } else {
          this.genderError = false
          callback();
        }
      }, 1000);
    };
    return{
      customerForm: {
        phone_number: '',
        name: '',
        email: '',
        gender: '',
        username: '',
        status: 'active'
      },
      customerFormRules: {
        phone_number: { validator: checkPhoneNumber, trigger: 'input'},
        name: { validator: checkName, trigger: 'blur'},
        email: { validator: checkEmail, trigger: 'blur'},
        gender: { validator: checkGender, trigger: 'change'}
      },
      nameError: false,
      phoneNumberError: false,
      emailError: false,
      genderError: false,
    }
  },
  methods: {
    validatorName(){
      let value = this.customerForm.name
      let input = value.charAt(value.length - 1);
      var inputBefore = value.charAt(value.length - 2);
      let letters = /^[A-Za-z. ]+$/
      if (value.length == 1) {
        if (input == " ") {
          this.customerForm.name = "";
        } else {
          this.customerForm.name = input.toUpperCase();
        }
      }
      if (!input.match(letters)) {
        this.customerForm.name = value.substr(0, value.length - 1);
      } else {
        if (input.match(" ") && input.match(inputBefore)) {
          this.customerForm.name = value.substr(0, value.length - 1);
        } else if (inputBefore.match(" ")) {
          this.customerForm.name = value.substr(0, value.length - 1) + input.toUpperCase();
        }
      }
    },
    validatorPhoneNumber(){
      let value = this.customerForm.phone_number
      let input = value.charAt(value.length - 1);
      if (value.length == 1) {
        if (input == "0") {
          this.customerForm.phone_number = "";
        } else {
          this.customerForm.phone_number = value
        }
      }
    },
    validatorEmail() {
      var value = this.customerForm.email;
      var input = value.charAt(value.length - 1);
      var letters = /^[A-Za-z0-9._+@]+$/;
      if (value == " ") {
        this.customerForm.email = "";
      }

      if (value.length > 100) {
        this.customerForm.email = value.substring(0, value.length - 1);
      }

      if (!input.match(letters)) {
        this.customerForm.email = value.substring(0, value.length - 1);
      }
    },
    selectGender(gender){
      this.customerForm.gender = gender
    },
    customUsername(){
      let d = new Date()
      let uniqueNumber = d.getSeconds()+d.getMilliseconds()
      this.customerForm.username =  this.customerForm.email.substring(0, this.customerForm.email.indexOf("@"))+uniqueNumber
    }
  }
}