<template>
   <div 
    class="modal fade" 
    id="popupEmptyStockProduct"
    tabindex="-1" 
    role="dialog" 
    aria-labelledby="popupEmptyStockProduct" 
    aria-hidden="true">
    <div 
    class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="font-roboto font-16 font-600 font-400 ">
            Beberapa Produk Tidak Tersedia
          </span>
          <button 
            ref="closeModalref"
            type="button" 
            class="close d-none" 
            data-bs-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <p class="font-14">Terdapat {{  emptyStockProduct.length }} produk yang tidak memiliki stok. Cek kembali produk yang tersedia di keranjang lalu lanjutkan proses order atau kembali untuk berbelanja produk lainnya</p>
          
          <div class="font-14 d-flex flex-column">
            <div class="mb-2">
              Produk Tidak Tersedia: 

            </div>
            <div class="border rounded p-3 d-flex flex-column list-empty-product">
              <div v-for="product in emptyStockProduct" :key="product.id" class="d-flex justify-content-between align-items-center">
                <div class="d-flex gap-2 align-items-center">
                  
                  <img :src="product.files[0].value" :alt="product.name" class="empty-product-image">
                  <div>
                    {{ product.name }}
                  </div>
                </div>
                <div class="font-500">
                  {{ formatRupiah(product.discounted_price, 'Rp.'  ) }}
                </div>
              </div>
            </div>
          </div>

          <button type="button" class="btn btn-primary mt-3 w-100"   
            data-toggle="modal"
            data-bs-dismiss="modal"
            data-bs-target="#popupEmptyStockProduct"> Oke </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

    import { mapState } from 'vuex'
    import IhsMixin from '@/mixins/ihs.mixins.js'

    export default {
      mixins: [IhsMixin],
      computed: {
      ...mapState('cart', [
        'cart'
      ]),
      emptyStockProduct () {
        return this.cart.products.filter(product => {
          return !product.stock
        })
      }
      },
    }
</script>

<style lang="scss" scoped>

  .list-empty-product {
    max-height: 300px;
    overflow-y: auto;
  }
  .empty-product-image {
    width: 55px;
    height: 55px;
    object-fit: cover;
  }
</style>