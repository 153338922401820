<template>
  <div 
    class="modal" 
    id="popupQr"
    tabindex="-1" 
    role="dialog" 
    aria-labelledby="popupQr" 
    aria-hidden="true">
    <div 
    class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="font-roboto font-16 font-400 mx-auto">
            Silahkan pindai Kode QR untuk melihat pesanan
          </span>
          <button 
            ref="closeModalref"
            type="button" 
            class="close d-none" 
            data-bs-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="qrcode-stream ">
            <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit" v-if="qrIsOn">
              <div class="d-flex flex-column h-100" v-if="loading">
                <div class="m-auto">
                  <skeleton-loader-vue
                    type="rect"
                    :width="262"
                    :height="262"
                    :radius="4"
                    animation="fade"
                  />
                </div>
              </div>
            </qrcode-stream>
            <div class="d-flex">
              <button class="switch-button mt-3 mx-auto" :disabled="loading" @click="switchCamera">
                <img src="@/assets/icons/switch-camera-icon.svg" alt="">
              </button>
            </div>
        
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  data() {
    return {
      loading: false,
      error: '',
      camera: 'front',
      qrIsOn: false,
      noRearCamera: false,
      noFrontCamera: false
    }
  },
  components: {
    QrcodeStream
  },
  methods: {
    ...mapActions('cart', ['getCart', 'getCartbyQr']),
    ...mapActions('user', ['getCustomer']),
    ...mapMutations('cart', ['SET_CART']),
    ...mapMutations('user', ['SET_SELECTED_CUSTOMER']),
    
      switchCamera () {
      switch (this.camera) {
        case 'front':
          this.camera = 'rear'
          break
        case 'rear':
          this.camera = 'front'
          break
      }
    },
    async onDecode (result) {
      try {
        const resp = await this.getCart({id:result})
        
        if (resp.data.status_code === 200) {
          await this.getCustomer({phone_number: resp.data.data.cart.phone_number})
          this.$refs.closeModalref.click()
          this.$message({
            showClose: true,
            message: 'Berhasil mendapatkan data keranjang',
            type: 'success'
          });
        }
      } catch (error) {
        if(error.response.status === 404) {
          this.$message({
            showClose: true,
            customClass: "error-message",
            message: "Kode pesanan tidak ditemukan",
            type: "error"
          });
        }
      }
    },

    async onInit (promise) {
      this.loading = true
      try {
        await promise
      } catch (error) {
         const triedFrontCamera = this.camera === 'front'
        const triedRearCamera = this.camera === 'rear'

        const cameraMissingError = error.name === 'OverconstrainedError'

        if (triedRearCamera && cameraMissingError) {
          this.noRearCamera = true
          this.camera = 'front'
          this.$message({
              showClose: true,
              customClass: "error-message",
              message: 'Perangkat Anda tidak memiliki kamera depan',
              type: "error"
            });
        }

        if (triedFrontCamera && cameraMissingError) {
          this.noFrontCamera = true
          this.camera = 'read'
          this.$message({
              showClose: true,
              customClass: "error-message",
              message: 'Perangkat Anda tidak memiliki kamera belakang',
              type: "error"
            });
        }

        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permisson"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        }
      } finally {
        this.loading = false
      }
    },
    
  },
  mounted() {
    const popupModal = document.getElementById('popupQr')
    const _this = this
    popupModal.addEventListener('hide.bs.modal', function () {
      _this.qrIsOn = false
    })
    popupModal.addEventListener('show.bs.modal', function () {
      _this.qrIsOn = true
    })
  }
}
</script>

<style lang="scss">
.qrcode-stream {
  margin: 20px auto;
  width: 262px;
  height: 262px;
}

.error-message.el-message--error.el-message {
  background-color: #E93636;
  border-color: #E93636;
  // top: inherit !important;
  // bottom: 30px;

  .el-icon-error {
    display: none;
  }
  .el-message__content {
    color: white !important;
  }
  .el-message__closeBtn {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.modal-content{
  background-color: rgba(0,0,0,.0001) !important;
  border: 0 !important;
}

.modal-header{
  color: white;
  border: 0 !important;
}

.switch-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.7);
  &:disabled {
  background: rgba(255, 255, 255, 0.438);

  }
}

</style>