<template>
  <div>
    <div 
      class="mt-3"
      v-for="(variant, type) in productSelected.variants" 
      :key="variant.id"  
      >
      <div class="content-wrapper">
        <span class="content-wrapper__section-title">
          {{ type }}
        </span>
        
        <div  class="variants-list d-flex flex-wrap gap-2">
          <div v-for="variantList in variant" :key="variantList.id" >
            <input 
              type="radio" 
              :ref="'drawer'+type+'-'+variantList.name" 
              :id="'drawer'+type+'-'+variantList.name" 
              :name="type" 
              :checked="productVariant.some(someVariant => someVariant.id === variantList.id)"
              :value="variantList.name"
              @click="selectVariants(variantList.id,type,variantList.name, variantList.discounted_price)"
            />
            <label :for="'drawer'+type+'-'+variantList.name">
              <div 
                v-if="type==='Warna'" 
                class="label-color" 
                :style="'background:'+ variantList.value"
              />
              <span class="text-nowrap">{{variantList.name}}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  computed: {
    ...mapState('product', ['productSelected', 'productVariant', 'variant']),
    isVariantSelectedAll() {
      return Object.keys(this.productSelected.variants).length === this.productVariant.length
    }
  },
  methods: {
    ...mapMutations('product', ['SELECT_VARIANT', 'VARIANT_RESULT']),
      getCombinations(permutation) {
        var length = permutation.length,
            result = [permutation.slice()],
            c = new Array(length).fill(0),
            i = 1, k, p;

        while (i < length) {
          if (c[i] < i) {
            k = i % 2 && c[i];
            p = permutation[i];
            permutation[i] = permutation[k];
            permutation[k] = p;
            ++c[i];
            i = 1;
            result.push(permutation.slice());
          } else {
            c[i] = 0;
            ++i;
          }
        }
        return result.map(variant => variant.join(', '));
      },
    selectVariants(variantId, variantName, variantValue, variantPrice){
      let variant = {}
      variant['id'] = variantId
      variant['name'] = variantName
      variant['value'] = variantValue
      variant['price'] = variantPrice
      this.SELECT_VARIANT(variant)
      let combination = this.productVariant.map((combine) => {
        return combine.value
      })
      
      const variantCombination = this.getCombinations(combination)
      
      let result = this.productSelected.details.filter((detail) => {
        return variantCombination.find(combinationName => combinationName === detail.name)
      })

      if(result.length){
        this.VARIANT_RESULT(result[0])
      } else {
        this.VARIANT_RESULT({})
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@mixin custom-radio($text-color, $border-color){
  font-weight: bold;
  font-size: 14px;
  color: $text-color;
  background: #FFFFFF;
  border: 1px solid $border-color;
  border-radius: 4px;
  padding: 2px 6px;
  margin:  0;
}

.variants-list{
  // overflow: auto;
  // @include scrolls-bar();
  margin-top: 4px;
  .variant-wrapper{
    display: inline-block;
  }

  input[type="radio"] {
    display:none;
  }

  label {
    @include custom-radio(#707071, #C1C1C2);
    cursor: pointer;
    // min-width: 90px;
    display: -webkit-inline-box;

    .label-color{
      width: 12px;
      height: 12px;
      margin: auto 4px auto 0;
      border-radius: 2px;
    }
  }

  input[type="radio"]:checked + label {
    @include custom-radio(#006CB5, #006CB5);
  }
}
</style>