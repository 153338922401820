<template>
	<div
		class="d-flex page-wrapper flex-column flex-lg-row h-100"
		:class="{'onSidebarOpen' : sidebarVisible}"
	>
		<product />
		<cart v-show="isOnShift" />
		<shift v-show="!isOnShift" />
		<start-shift @start-shift="start" />
		<end-shift />
	</div>
</template>

<script>
import IhsMixin from "@/mixins/ihs.mixins.js";

import { mapState, mapMutations, mapActions } from "vuex";

import Product from "@/components/product/Product.vue";
import Shift from "@/components/shift/Shift.vue";
import Cart from "@/components/cart/Cart.vue";
import EndShift from "@/components/shift/EndShift.vue";
import StartShift from "@/components/shift/StartShift.vue";

export default {
	mixins: [IhsMixin],
	data() {
		return {
			productBarcode: ""
		};
	},
	components: {
		Product,
		Shift,
		Cart,
		StartShift,
		EndShift
	},
	computed: {
		...mapState("home", ["sidebarVisible"]),
		...mapState("shift", ["shiftId", "shift", "isOnShift"])
	},
	methods: {
		...mapActions("product", ["getProductByBarcode"]),
		...mapActions("shift", ["getShift", "startShift"]),
		...mapMutations("cart", ["SET_CART", "SET_VOUCHER"]),
		...mapMutations("product", ["VARIANT_RESULT"]),
		async start(balance) {
			try {
				const payload = {
					data: {
						initial_balance: balance
					}
				};
				await this.startShift(payload);
			} catch (e) {
				console.log(e);
			}
		}
	},
	mounted() {
		this.getShift();
	}
};
</script>

<style lang="scss" scoped>
.page-wrapper {
	background: #f5f5f5;
	transition: margin 400ms ease-in-out;
	overflow-x: hidden;
	position: relative;
	&.onSidebarOpen {
		margin-left: 78px;
	}
}
</style>

