<template>
    <content-wrapper />
</template>

<script>
import ContentWrapper from '@/components/Content.vue'

export default {
  components:{
    ContentWrapper,
  }  
}
</script>