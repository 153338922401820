<template>
    <div class="modal fade" id="popupProductPrice" tabindex="-1" ref="modal" aria-labelledby="PopupProductPrice"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <span class="font-nunito font-18 font-700" id="exampleModalLongTitle">
                        Ketentuan Harga
                    </span>
                    <button type="button" ref="closeModalref" class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body ">
                    <div class="d-flex flex-column" v-if="Object.keys(productSelected).length">
                        <div class="product-information">
                            <img class="product-information__image" :src="productSelected.files[0].value" alt="">
                            {{productSelected.name}}
                        </div>
                        <div class="row mt-3">
                            <div class="col-6"></div>
                            <div class="col-6 font-14">
                                <div class="row">
                                    <div class="col-6 color-grey-90">
                                        Min. Beli
                                    </div>
                                    <div class="col-6 color-grey-90">
                                        Harga
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row mt-2 font-14">
                            <div class="col-6">
                                Retail
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-6">
                                        1
                                    </div>
                                    <div class="col-6">
                                        {{productSelected.price}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2 font-14">
                            <div class="col-6">
                                Semi
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-6">
                                        {{productSelected.min_qty_semi ? productSelected.min_qty_semi : '-'}}
                                    </div>
                                    <div class="col-6">
                                        {{productSelected.semi_price ? productSelected.semi_price  : '-'}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2 font-14">
                            <div class="col-6">
                                Grosir
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-6">
                                        {{productSelected.min_qty_grocery  ? productSelected.min_qty_grocery : '-'}}
                                    </div>
                                    <div class="col-6">
                                        {{productSelected.grocery_price ? productSelected.grocery_price : '-'}}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import IhsMixin from '@/mixins/ihs.mixins.js'
  
  import { mapState } from 'vuex'

  
  export default {
    mixins: [IhsMixin],
    computed : {
        ...mapState('product', ['productSelected'])
    }
  }
</script>
  
 
  
  <style lang="scss" scoped>
  .modal-dialog {
    width: 473px;
  }

  .product-information {
    border: 0.5px solid #D9D9DA;
    padding: 15px;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    gap: 10px;
    border-radius: 10px;
    &__image {
        width: 64px;
        height: 64px;
        object-fit: fill;
    }
  }
  </style>