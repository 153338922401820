<template>
  <div class="px-4 py-3 stopwatch-container d-flex justify-content-between align-items-center">
      <span class="timer font-18 font-nunito font-700"> {{time}}</span>
     
      <button class="btn btn-outline-danger font-14 font-700 font-nunito" data-bs-toggle="modal" data-bs-target="#endShift">Akhiri Shift</button>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
     data() {
        return {
            time: "00:00:00",
        }
    },
    computed : {
        ...mapState('shift', ['shift']),
    },
    methods : {
        ...mapActions('shift', ['exportShiftTransaction']),
    },
    created() {

        let callback = () => {
            if(Object.keys(this.shift).length) {
                const timenow = this.$moment().utc()
                const then = this.$moment(`${this.shift.created_at} ${this.shift.start_hour}`, "DD/MM/YYYY HH:mm:ss").utc();
                this.time = this.$moment.utc(timenow.diff(then)).format('HH:mm:ss')
            } else {
                this.time = '00:00:00'
            }
    
        };

        setInterval(callback, 1000);
    }
}
</script>

<style lang="scss" scoped>
    .timer {
        color: #E93636;
    }
    .stopwatch-container {
        background-color: #FFF3F3;
        width: 100%;
    }
</style>