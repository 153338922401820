<template>
  <div ref="popupCustomerForm" class="modal fade" id="popupCustomerForm" tabindex="-1" role="dialog"
    aria-labelledby="popupCustomerForm" aria-hidden="true" data-bs-backdrop="static">

    <div class="
      modal-dialog 
      modal-dialog-centered" role="document">

      <div class="modal-content">

        <div class="modal-header">
          <span class="
            font-nunito 
            font-18 
            font-700">
            Tambah Pelanggan Baru
          </span>
          <button type="button" ref="closePopupCustomerForm" @click="resetForm" class="btn-close" data-bs-dismiss="modal"
            aria-label="Close"></button>


          <button ref="triggerClosePopupCustomer" type="button" class="d-none" data-toggle="modal" data-bs-dismiss="modal"
            data-bs-target="#popupCustomer" @click="deleteAllBackdrop">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="
            font-roboto 
            font-14 
            d-flex 
            flex-column">

            <el-form ref="customerForm" :model="customerForm" :rules="customerFormRules">

              <div class="phoneNumber d-flex flex-column" :class="phoneNumberError ? 'mb-4' : 'mb-3'">
                <div class="mb-1">
                  <span>No. Telepon</span>
                </div>
                <el-form-item prop="phone_number" class="mb-0">
                  <el-input ref="phoneNumberInput" v-model="customerForm.phone_number" @input="validatorPhoneNumber()"
                    :controls="false" type="number" class="w-100">
                    <template slot="prefix">
                      +62
                    </template>
                  </el-input>
                </el-form-item>
              </div>

              <div class="d-flex flex-column" :class="nameError ? 'mb-4' : 'mb-3'">
                <div class="mb-1">
                  <span>Nama Lengkap</span>
                </div>
                <el-form-item prop="name" class="mb-0">
                  <el-input v-model="customerForm.name" @input="validatorName()" class="w-100" />
                </el-form-item>
              </div>

              <div class="d-flex flex-column" :class="emailError ? 'mb-4' : 'mb-3'">
                <div class="mb-1">
                  <span>Email (Opsional)</span>
                </div>
                <el-form-item prop="email" class="mb-0">
                  <el-input type="email" v-model="customerForm.email" class="w-100" />
                </el-form-item>
              </div>

              <div class="gender d-flex flex-column" :class="genderError ? 'mb-3' : ''">
                <div>
                  <span>Jenis Kelamin</span>
                </div>
                <el-form-item prop="gender">
                  <span class="custom-gender-buttons">
                    <label class="radio" id="a-button">
                      <input ref="MaleBtn" name="gender" type="radio" value="male" v-model="customerForm.gender"
                        id="a-button" @click="selectGender('male')" />
                      <span>Laki-Laki</span>
                    </label>

                    <label class="radio ms-3" id="b-button">
                      <input ref="FemaleBtn" name="gender" type="radio" value="female" v-model="customerForm.gender"
                        id="b-button" @click="selectGender('female')" />
                      <span>Perempuan</span>
                    </label>
                  </span>
                </el-form-item>
              </div>

              <div class="d-flex mb-3">
                <button ref="customerFormSubmit" type="button" @click="submitForm()" class="btn btn-primary py-2 w-100">
                  <span class="font-nunito font-16 font-700 text-white">
                    Tambahkan Pelanggan Baru
                  </span>
                </button>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import customerForm from '@/mixins/customerForm.mixins.js'

export default {
  mixins: [customerForm],
  methods: {
    ...mapActions('user', ['addCustomer', 'getCustomer']),
    ...mapActions('cart', ['getCartbyQr', 'getCart']),
    ...mapMutations('user', ['SET_SELECTED_CUSTOMER']),

    submitForm() {
      this.$refs.customerFormSubmit.disabled = false
      this.$refs['customerForm'].validate((valid) => {
        if (valid) {
          this.customerAdd()
        } else {
          this.$refs.customerFormSubmit.disabled = false
          return false
        }
      });
    },
    resetForm() {
      this.$refs['customerForm'].resetFields();
      this.deleteAllBackdrop()
    },
    async customerAdd() {
      try {
        const response = await this.addCustomer({
          data: this.customerForm
        })
        if (response.data.status_code === 201) {
          this.$message({
            showClose: true,
            message: 'Berhasil Menambahkan Customer',
            type: 'success'
          });
          this.getCart({id: this.customerForm.phone_number}).then(
            this.getCustomer({ phone_number: this.customerForm.phone_number })
          )
          this.$refs['customerForm'].resetFields();
          this.$refs.closePopupCustomerForm.click()
        }
      } catch (error) {
        this.$refs.closePopupCustomerForm.click()
        if (error.response.data.errors.phone_number) {
          if (error.response.data.errors.phone_number[0] === 'The phone number must not be greater than 15 characters.') {
            this.$message({
            showClose: true,
            message: "Nomor Telepon Tidak dapat lebih dari 15 Karakter",
            type: 'error'
          });
          } else {
            this.$message({
            showClose: true,
            message: error.response.data.errors.phone_number[0],
            type: 'error'
          });
          }
       
        } else {
          // Display a general error message if the specific error is not found
          this.$message({
            showClose: true,
            message: "An error occurred. Please check your input.",
            type: 'error'
          });
        }
      }
    },
    deleteAllBackdrop() {
      // Get all elements with the class 'modal-backdrop'
      const modalBackdrops = document.querySelectorAll('.modal-backdrop');
      // Iterate through the list of elements and remove each one
      modalBackdrops.forEach(modalBackdrop => {
        modalBackdrop.remove();
      });
    }
  }
}
</script>

<style lang="scss">
.phoneNumber {

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .el-input__prefix {
    margin: auto;
    padding: 0 10px 0 5px;
    display: flex;
  }

  .el-input__inner {
    padding-left: 50px !important;
  }
}

.gender {
  .el-form-item__content {
    line-height: 20px;
  }
}
</style>

<style lang="scss" scoped>
.modal-dialog {
  max-width: 570px;
}

.modal-content {
  min-width: 570px;
  min-height: 448px;
}

.modal-body {
  padding-bottom: 0;
}

.custom-gender-buttons {
  display: inline-block;

  label {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.65;

    span {
      display: inline-block;
    }
  }
}

.radio {
  margin: 5px 0 0 0;
  display: inline-block;
  cursor: pointer;

  input {
    display: none;

    &#a-button+span:after {
      border: black 2px solid;
    }

    &#b-button+span:after {
      border: black 2px solid;
    }

    &+span {
      line-height: 15px;
      height: 15px;
      padding-left: 20px;
      position: relative;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;

      &:not(:empty) {
        padding-left: 20px;
      }

      &:before,
      &:after {
        content: '';
        width: 16px;
        height: 16px;
        display: block;
        border-radius: 50%;
        left: 0;
        top: 0;
        position: absolute;
      }

      &:before {
        transition: background .2s ease, transform .4s cubic-bezier(.175, .885, .32, 2);
      }

      &#a-button:before {
        background: #006CB5;
      }

      &#b-button:before {
        background: #006CB5;
      }

      &:after {
        background: white;
        transform: scale(.78);
        transition: transform .6s cubic-bezier(.175, .885, .32, 1.4);
      }
    }

    &:checked+span {
      &:before {
        transform: scale(1.04);
      }

      &:after {
        transform: scale(.4);
        transition: transform .3s ease;
      }
    }

    &#a-button:checked+span {
      &:before {
        background: white;
        border: 2px solid #006CB5;
      }

      &:after {
        background: #006CB5;
        border: 2px solid #006CB5;
      }
    }

    &#b-button:checked+span {
      &:before {
        background: white;
        border: 2px solid #006CB5;
      }

      &:after {
        background: #006CB5;
        border: 2px solid #006CB5;
      }
    }
  }

  &:hover {
    input {
      &+span {
        &:before {
          transform: scale(.92);
        }

        &:after {
          transform: scale(.74);
        }
      }

      &:checked+span {
        &:after {
          transform: scale(.4);
        }
      }
    }
  }
}
</style>